<div class="screen main" #main>
  <div class="inner">
    <div class="headline">
      <div class="up">
        <h1>There’s No Place</h1>
      </div>
      <div class="bottom">
        <h1>Like Smart Home</h1>
      </div>
    </div>
    <div class="download">
      <div class="google">
        <img class="google" src="/assets/wise-home/1 - Big photo/Download - Play Store.png"/>
      </div>
      <div class="apple">
        <img class="apple" src="/assets/wise-home/1 - Big photo/Download - App Store.png"/>
      </div>
    </div>
    <div class="scroll">
      <div class="icon" (click)="scroll(about, 'smooth')">
        <img src="/assets/wise-home/1%20-%20Big%20photo/Scroll.svg"/>
      </div>
    </div>
  </div>
</div>

<div #about class="screen about" id="about">
  <div class="logo">
    <img src="/assets/wise-home/2 - App name/app title.svg">
  </div>

  <div class="info">
    <div>Responsibilities: <span class="bold">UX | UI Design</span></div>
    <div>Platform: <span class="bold">Tablet & Mobile App</span></div>
  </div>

</div>

<div class="screen introduction" id="introduction">
  <div class='picture'>
    <img class="desktop" src="/assets/wise-home/3 - Introduction/Tablet&phone%20mockup.png"/>
    <img class="mobile" src="/assets/wise-home/Mobile/WH%20mockups%20mobile.png"/>
  </div>
  <app-text-paragraph [headline]="'All You Need In A\n Single App'" [subHeadline]="'Introduction'"
                      [text]="texts.introduction">
  </app-text-paragraph>
</div>

<div class="screen user-research" id="user-research">
  <app-text-paragraph [headline]="'Too Many Apps For A Smart Home'"
                      [subHeadline]="'User Research'" [text]="texts.userResearch">
  </app-text-paragraph>
  <div class="picture">
    <img src="/assets/wise-home/4 - User research/Girl Many Apps.png"/>
  </div>
</div>
<!-- TODO: Mobile! -->
<div class="screen first-persona" id="first-persona">
  <div class="headline">
    <img src="/assets/wise-home/5%20-%20Persona%2001/headline%201.svg"/>
  </div>
  <div class="picture">
  </div>
  <div class="container">
    <div class="upper">
      <div class="text" [innerHTML]="persona.first.text"></div>
    </div>
    <div class="bottom">
      <h4 class="text" [innerHTML]="persona.first.headline"></h4>
    </div>
  </div>
</div>
<!-- TODO: Mobile! -->
<div class="screen second-persona" id="second-persona">
  <div class="headline">
    <img src="/assets/wise-home/6%20-%20Persona%2002/headline2.svg"/>
  </div>
  <div class="picture">
  </div>
  <div class="spacer"></div>
  <div class="container">
    <div class="upper">
      <h4 class="text" [innerHTML]="persona.second.headline | breakline"></h4>
    </div>
    <div class="bottom">
      <div class="text" [innerHTML]="persona.second.text | breakline"></div>
    </div>
  </div>
</div>

<div class="screen user-research-details" id="user-research-details">
  <div class="picture">
    <img src="/assets/wise-home/7%20-%20User%20research%20part%20two/Man%20Home.png"/>
  </div>
  <div class="side">
    <app-text-paragraph [headline]="'What Really Matters To\n The Users'"
                        subHeadline="User Research"></app-text-paragraph>
    <div class="container">
      <div class="first">
        <h3 class="headline">{{userResearchDetails.first.headline}}</h3>
        <p class="text">{{userResearchDetails.first.text}}</p>
      </div>
      <div class="second">
        <h3 class="headline">{{userResearchDetails.second.headline}}</h3>
        <p class="text">{{userResearchDetails.second.text}}</p>
      </div>
      <div class="third">
        <h3 class="headline">{{userResearchDetails.third.headline}}</h3>
        <p class="text">{{userResearchDetails.third.text}}</p>
      </div>
      <div class="fourth">
        <h3 class="headline">{{userResearchDetails.fourth.headline}}</h3>
        <p class="text">{{userResearchDetails.fourth.text}}</p>
      </div>
    </div>
  </div>
</div>

<div class="screen information-architecture" id="information-architecture">
  <div class="inner">
    <app-text-paragraph [headline]="'Building The Roots'" [subHeadline]="'Information Architecture'"
                        [text]="texts.informationArchitecture">
    </app-text-paragraph>
    <div class="picture">
      <img src="/assets/wise-home/8 - Information architecture/flowchart.svg"/>
    </div>
  </div>
</div>

<div class="screen wireframes" id="wireframes">
  <div class="picture">
    <img class="desktop" src="/assets/wise-home/9 - Scanned wireframes/Scanned Wireframes hand.png"/>
    <img class="mobile" src="/assets/wise-home/Mobile/Mobile Scanned Wireframes hand.png"/>
  </div>
  <app-text-paragraph [headline]="'Put Ideas On Paper'" [subHeadline]="'Wireframes'"
                      [text]="texts.wireframes">
  </app-text-paragraph>
</div>

<div class="screen tablet-wireframes" id="tablet-wireframes">
  <app-text-paragraph headline="Visualize The Solution" subHeadline="Wireframes"></app-text-paragraph>
  <div class="picture">
    <img class="desktop" src="/assets/wise-home/10-11%20-%20Tablet%20wireframes/tablet wireframes.png"/>
    <img class="mobile" src="/assets/wise-home/Mobile/Mobile%20wireframes.png"/>
  </div>
</div>

<div class="screen phone-wireframes" id="phone-wireframes">
  <div class="picture">
    <img src="/assets/wise-home/12-13%20-%20Phone%20wireframes/wireframes phones.png"/>
  </div>
</div>


<div class="screen design" id="design">
  <div class="phone">
    <app-text-paragraph [headline]="'Clean And Informative\n User Interface'" [subHeadline]="'Design'"
                        [text]="texts.phoneDesign">
    </app-text-paragraph>
    <div class="picture">
      <app-phone-player videoSrc="/assets/wise-home/14 - Design phone/Mobile design WH.gif"></app-phone-player>
    </div>
  </div>
  <div id="wise-home-tablet" class="tablet">
    <div class="picture">
      <app-tablet-player videoSrc="/assets/wise-home/15 - Design Tablet/Tablet design WH.gif"></app-tablet-player>
    </div>
  </div>
</div>


<div class="screen typography" id="typography">
  <div class="inner">
    <div class="typography-container">
      <app-text-paragraph [subHeadline]="'Typography'" [headline]="'Modern & Clean Font'" [text]="''">
      </app-text-paragraph>
      <div class="picture">
        <img src="/assets/wise-home/16 - Typhography-icons/typography.svg"/>
      </div>
    </div>
    <div class="icons-container">
      <app-text-paragraph [subHeadline]="'Icons'" [headline]="'Realistic & indicative icons'" [text]="''">
      </app-text-paragraph>
      <div class="picture">
        <img class="desktop" src="/assets/wise-home/16 - Typhography-icons/icons home.svg"/>
        <img class="mobile" src="/assets/wise-home/Mobile/Icons%20WH%20mobile.svg"/>
      </div>
    </div>
  </div>
</div>

<div class="screen colors" id="colors">
  <app-text-paragraph [headline]="'Calming & Smart Colors'" [subHeadline]="'Colors'"
                      [text]="texts.colors">
  </app-text-paragraph>
  <div class="picture">
    <img src="/assets/wise-home/17 - Colors/Lights - colors.svg"/>
  </div>
  <div class='colors-container'>
    <app-color class="color" *ngFor="let color of colors" [color]="color.value" [isDark]="color.isDark"
               [isShadow]="color.isShadow"></app-color>
  </div>
</div>

<div class="screen show-off-tablets" id="show-off-tablets">
  <div class="desktop">
    <div class="single">
      <img src="/assets/wise-home/18 - MockupsDesign Tablet/tablet simple white 2lines.png"/>
    </div>
    <div class="display">
      <img src="/assets/wise-home/18 - MockupsDesign Tablet/mockups%20tablet%204.png"/>
    </div>

    <div class="lines-container">
      <div class="line first"></div>
      <div class="line spacer"></div>
      <div class="line second"></div>
      <div class="line big-spacer"></div>
      <div class="line third"></div>
    </div>
  </div>
  <div class="mobile">
    <app-tablet-image-slider [images]="showOffTabletsImages"></app-tablet-image-slider>
  </div>
</div>
<div class="screen show-off-phones" id="show-off-phones">
  <img class="desktop" src="/assets/wise-home/19-20 - Mockups Design Phones/mockups%20phone%20WH%20new.png"/>
  <app-mobile-image-slider class="mobile" [images]="showOffPhoneImages"></app-mobile-image-slider>
</div>

<div class="screen thanks" id="thanks">
  <div class="picture">
    <img src="/assets/wise-home/21%20-%20Thanks/logo.svg"/>
  </div>
  <h1 class='content'>Thanks For Viewing!</h1>
</div>
<div class="screen contact" id="contact">
  <div class="navigation">
    <div class="prev" routerLink="/addict">
      <div class="container">
        <div class="content">
          <h2 class="headline">Addict</h2>
          <div class="description">Re-Design, Branding & <br/> UX | UI Design</div>
        </div>
        <div class="spacer"></div>
        <img class="img desktop" src="/assets/home-page/5%20-%20ecommerce/mockups%20addict.png"/>
        <img class="img mobile" src="/assets/previous-next-mobile/Addict%20mockup%20square.png"/>
      </div>
    </div>
    <div class="next" routerLink="/beautyzone">
      <div class="container">
        <div class="content">
          <h2 class="headline">Beauty Zone</h2>
          <div class="description">UX | UI Design Mobile App</div>
        </div>
        <div class="spacer"></div>
        <img class="img desktop" src="/assets/home-page/3%20-%20Beautyzone/Phones.svg"/>
        <img class="img mobile" src="/assets/previous-next-mobile/BZ%20mockup%20square.png"/>
      </div>
    </div>
  </div>
  <div class="info">
    <h1 class="headline">Start a project with me!</h1>
    <div class=container>
      <div class="mail">Amitsheleg13@gmail.com</div>
      <div class="phone">053-3350577</div>
    </div>
    <div class="goto-top">
      <img (click)="scroll(main)" src="/assets/wise-home/22 - Next - contact/up.svg"/>
    </div>
  </div>

</div>
