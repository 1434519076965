<div class="screen main" #main>
  <div class="picture">
    <img src="/assets/beauty-zone/1%20-%20Cover/BeautyZone-cover%20photo.png" />
  </div>
  <div class="inner">
    <div class="headline">
      <div class="up">
        <h1>Find The Beauty</h1>
      </div>
      <div class="bottom">
        <h1>Within You</h1>
      </div>
    </div>
    <div class="download">
      <div class="google">
        <img class="google" src="/assets/beauty-zone/1%20-%20Cover/Download%20-%20Google%20Play.png" />
      </div>
      <div class="apple">
        <img class="apple" src="/assets/beauty-zone/1%20-%20Cover/Download%20-%20App%20Store.png" />
      </div>
    </div>
    <div class="scroll">
      <div class="icon" (click)="scroll(about, 'smooth')">
        <img src="/assets/beauty-zone/1%20-%20Cover/Scroll%20Icon.svg" />
      </div>
    </div>
  </div>
</div>

<div #about class="screen about" id="about">
  <div class="logo">
    <img src="/assets/beauty-zone/2%20-%20App%20name/Logo%20Name.svg">
  </div>

  <div class="info">
    <div>Responsibilities: <span class="bold">UX | UI Design</span></div>
    <div>Platform: <span class="bold">Mobile Application</span></div>
  </div>

</div>

<div class="screen introduction" id="introduction">
  <app-text-paragraph [headline]="'The Beauty Zone'" [subHeadline]="'Introduction'" [text]="texts.introduction">
  </app-text-paragraph>
  <div class='picture'>
    <img src="/assets/beauty-zone/3%20-%20Introduction/Mockup%20eyeshadow.png" />
  </div>
</div>

<div class="screen user-research" id="user-research">
  <div class="picture">
    <img src="/assets/beauty-zone/4%20-%20User%20research/girl%20with%20makeup%20background.png" />
  </div>
  <app-text-paragraph [headline]="'There Is No Social \n Platform For Beauty Lovers'" [subHeadline]="'User Research'"
    [text]="texts.userResearch">
  </app-text-paragraph>
</div>

<div class="screen first-persona" id="first-persona">
  <div class="headline">
    <picture>
      <img src="/assets/beauty-zone/5%20-%20Persona%2001/name%201.svg" />
      <img class="mobile" src="/assets/mobile/mobile%20name%201.svg" />
    </picture>
  </div>
  <div class="inner">
    <p class="text" [innerHTML]="texts.firstPersona | breakline">
    </p>
  </div>
</div>

<div class="screen second-persona" id="second-persona">
  <div class="headline">
    <img src="/assets/beauty-zone/6%20-%20Persona%2002/name%202.svg" />
    <img class="mobile" src="/assets/mobile/mobile%20name%201-1.svg" />
  </div>
  <div class="inner">
    <p class="text" [innerHTML]="texts.secondPersona | breakline">
    </p>
  </div>
</div>

<div class="screen market-research" id="market-research" [routerLink]="route" [fragment]="'market-research'">
  <div class="container">
    <app-text-paragraph [headline]="'No Solution For Beauty Lovers'" [subHeadline]="'Market Research'"
      [text]="texts.marketResearch">
    </app-text-paragraph>
    <div class="pain-points">
      <app-pain-point class="pain-point" [subHeadline]="'PAIN POINT #'+ increase(i)" [headline]="painPoint.headline"
        [text]="painPoint.text" *ngFor="let painPoint of painPoints; index as i;">
      </app-pain-point>
    </div>
  </div>
</div>

<div class="screen information-architecture" id="information-architecture">
  <div class="inner">
    <app-text-paragraph [headline]="'Building The Roots'" [subHeadline]="'Information Architecture'"
      [text]="texts.informationArchitecture">
    </app-text-paragraph>
    <div class="picture">
      <img src="/assets/beauty-zone/9%20-%20Information%20architecture/Flow%20BZ.svg" />
    </div>
  </div>
</div>

<div class="screen wireframes" id="wireframes">
  <div class="picture">
    <img class="desktop" src="/assets/beauty-zone/10 - Scanned wireframes/Wireframes scans hand.png"/>
    <img class="mobile" src="/assets/beauty-zone/Mobile/Mobile%20Wireframes%20scans.png"/>
  </div>
  <app-text-paragraph [headline]="'Put Ideas On Paper'" [subHeadline]="'Wireframes'" [text]="texts.wireframes">
  </app-text-paragraph>
</div>

<div class="screen wireframes-design" id="wireframes-design">
  <div class="mobile">
    <app-text-paragraph class="headline" [headline]="'Visualize The Solution'"
                        [subHeadline]="isUI ? 'Design': 'Wireframes'"></app-text-paragraph>
    <div class="selected-menu-item">{{menuHeader}}</div>
    <app-mobile-image-slider (OnSelectedImageIndexChange)="setMenuHeaderByIndex($event)"
                             [images]="mobileImageSliderDesignGifsImages"></app-mobile-image-slider>
  </div>
  <div class="desktop">
    <div class="side">
      <app-text-paragraph [headline]="'Visualize The Solution'" [subHeadline]="isUI ? 'Design': 'Wireframes'">
      </app-text-paragraph>
      <div class="menu">
        <ul>
          <li class="item" (click)="setMenuItem('Onboarding')"
              [ngClass]="{selected: wireframesDesignMenu.selectedItem === 'Onboarding'}">Onboarding
          </li>
          <li class="item" (click)="setMenuItem('Home Page')"
              [ngClass]="{selected: wireframesDesignMenu.selectedItem === 'Home Page'}">Home Page
          </li>
          <li class="item" (click)="setMenuItem('Shop Page')"
              [ngClass]="{selected: wireframesDesignMenu.selectedItem === 'Shop Page'}">Shop Page
          </li>
          <li class="item" (click)="setMenuItem('Products Identifier')"
              [ngClass]="{selected: wireframesDesignMenu.selectedItem === 'Products Identifier'}">Products Identifier
          </li>
          <li class="item" (click)="setMenuItem('My Profile')"
              [ngClass]="{selected: wireframesDesignMenu.selectedItem === 'My Profile'}">My Profile
          </li>
        </ul>
      </div>
    </div>
    <div class="phone-container">
      <div class="content-container">
        <img [src]="wireframesDesignMenu.imageTextUrl" />
      </div>
      <app-phone-player class="display" [videoSrc]="wireframesDesignMenu.videoSrc"></app-phone-player>
      <app-toggle class="toggle-button" (onToggle)="changeUIUX($event)"></app-toggle>
    </div>
  </div>
</div>

<div class="screen colors" id="colors">
  <app-text-paragraph [headline]="'Feminine & Elegant'" [subHeadline]="'Colors'" [text]="texts.colors">
  </app-text-paragraph>

  <div class="picture">
    <img src="/assets/beauty-zone/12%20-%20Colors/Colors%20-%20Makeup%20Swatches.png" />
  </div>
</div>

<div class="screen typography-icons" id="typography-icons">
  <div class="container">
    <div class="typography">
      <app-text-paragraph subHeadline="Typography" headline="San Serif & Modern Font"></app-text-paragraph>
      <img src="/assets/beauty-zone/13%20-%20Typhography-icons/typography.svg" />
    </div>
    <div class="icons">
      <app-text-paragraph subHeadline="Icons" headline="Thin & Rounded Icons"></app-text-paragraph>
      <img src="/assets/beauty-zone/13%20-%20Typhography-icons/icons bz.svg" />
    </div>
  </div>
  <div class="picture">
    <img src="/assets/beauty-zone/13%20-%20Typhography-icons/crushed eyeshadows brush.png" />
  </div>
</div>
<!-- TODO: Mobile! -->
<div class="screen onboarding-preview" id="onboarding-preview">
  <div class="container">
    <div class="parallelogram">
    </div>
    <img src="/assets/beauty-zone/14-15%20-%20Onboarding/Design%20onboarding%20mockups.png" />
  </div>
</div>
<div class="screen preview" id="preview">
  <div class="container">
    <div class="parallelogram">
    </div>
    <img src="/assets/beauty-zone/16-17%20-%20Design%20Mockups/phone%20mockups%20-%20design.png" />
  </div>
  <app-mobile-image-slider class="mobile" [images]="mobileImageSliderDesignMockups"></app-mobile-image-slider>
</div>

<div class="screen thanks" id="thanks">
  <div class="picture">
    <img src="/assets/beauty-zone/18%20-%20Thanks/Logo.svg" />
  </div>
  <h1 class='content'>Thanks For Viewing!</h1>
</div>
<div class="screen contact" id="contact">
  <div class="navigation" routerLink="/wisehome">
    <div class="prev">
      <div class="content">
        <h2 class="headline">Wise Home</h2>
        <div class="description">UX | UI Design Mobile & Tablet App</div>
      </div>
      <img class="img" src="/assets/home-page/4%20-%20Wisehome/mockups%20WH%20full.png" />
    </div>
    <div class="next" routerLink="/addict">
      <div class="content">
        <h2 class="headline">Addict</h2>
        <div class="description">Re-Design, Branding & UX | UI Design</div>
      </div>
      <img class="img" src="/assets/home-page/5%20-%20ecommerce/mockups%20addict.png" />
    </div>
  </div>
  <div class="info" id="info">
    <h1 class="headline">Start a project with me!</h1>
    <div class=container>
      <div class="mail">Amitsheleg13@gmail.com</div>
      <div class="phone">053-3350577</div>
    </div>
    <div class="goto-top">
      <img (click)="scroll(main)" src="/assets/beauty-zone/19%20-%20Next%20-contact/up.svg" />
    </div>
  </div>

</div>
