<div class="screen main" #main>
  <div class="picture">
    <img src="/assets/addict/1 - Big photo/woman%20shopping.png"/>
  </div>
  <div class="inner">
    <div class="headline">
      <div class="up">
        <h1>Fashion is Art &</h1>
      </div>
      <div class="bottom">
        <h1>You are the Canvas</h1>
      </div>
    </div>
    <div class="scroll">
      <div class="icon" (click)="scroll(about, 'smooth')">
        <img src="/assets/addict/1 - Big photo/Scroll.svg"/>
      </div>
    </div>
  </div>
</div>

<div #about class="screen about" id="about">
  <div class="logo">
    <img src="/assets/addict/2 - App name/app title.svg">
  </div>

  <div class="info">
    <div class="desktop">Responsibilities: <span class="bold">Re-Design, Branding & UX | UI Design</span></div>
    <div class="mobile">Responsibilities: <span class="bold">Re-Design, Branding & <br/> UX | UI Design</span></div>
    <div>Platform: <span class="bold">Desktop & Mobile E-commerce</span></div>
  </div>

</div>

<div class="screen introduction" id="introduction">
  <app-text-paragraph [headline]="'Online Shopping ADDICT'" [subHeadline]="'Introduction'" [text]="texts.introduction">
  </app-text-paragraph>
  <div class='picture'>
    <img src="/assets/addict/3 - Introduction/Girl%20Boxes2.png"/>
  </div>
</div>

<div class="screen problem" id="problem">
  <div class='picture'>
    <img class="desktop" src="/assets/addict/4%20-%20The%20problem/only woman.png"/>
    <img class="mobile" src="/assets/addict/mobile/Woman%20card.png"/>
  </div>
  <app-text-paragraph [headline]="'Old-Fashion Experience'" [subHeadline]="'The Problem'" [text]="texts.problem">
  </app-text-paragraph>
</div>

<div class="screen solution" id="solution">
  <app-text-paragraph class="desktop" [headline]="'Approachable &\nInviting Website'" [subHeadline]="'The Solution'"
                      [text]="texts.solution"></app-text-paragraph>
  <app-text-paragraph class="mobile" [headline]="'Approachable & Inviting Website'" [subHeadline]="'The Solution'"
                      [text]="texts.solution"></app-text-paragraph>
  <div class='picture'>
    <img class="desktop" src="/assets/addict/5-6%20-%20Solution/wireframe%20arrows.svg"/>
    <img class="mobile" src="/assets/addict/mobile/Wireframe.png"/>
  </div>
  <img class="mobile" src="/assets/addict/mobile/Text%20-%20dots.svg" />
</div>

<div class="screen product-research" id="product-research">
  <div class="picture">
    <img src="/assets/addict/7%20-%20Solution2/text%20arrows.svg"/>
  </div>
</div>

<div class="screen design-modern-website" id="design-modern-website">
  <app-text-paragraph [headline]="'Re-branded & Modern Design'" [subHeadline]="'Design'">
  </app-text-paragraph>
  <div class="picture">
    <img class="desktop" src="/assets/addict/8-12-%20Design1/menu.png"/>
    <img class="desktop" src="/assets/addict/8-12-%20Design1/Home Page - Neige 2.png"/>
    <img class="mobile" src="/assets/addict/mobile/1%20Menu.png"/>
    <img class="mobile" src="/assets/addict/mobile/2%20Home%20Page.png"/>
  </div>
</div>

<div class="screen design-showoff" id="design-showoff">
  <div class="picture">
    <img class="desktop" src="/assets/addict/13-15%20-%20Design%202/Designs.png"/>
    <img class="mobile" src="/assets/addict/mobile/3%20Search%20Page.png"/>
  </div>

  <div class="logo-top">
    <img src="/assets/addict/8-12-%20Design1/logo%20round.png"/>
  </div>
  <div class="logo-bottom">
    <img src="/assets/addict/13-15%20-%20Design%202/logo.png"/>
  </div>

</div>

<div class="screen mobile-design" id="mobile-design">
  <app-text-paragraph [headline]="'Responsive Mobile Design'" [subHeadline]="'Design'">
  </app-text-paragraph>
  <div class="picture">
    <img class="desktop" src="/assets/addict/16-20%20-%20Phone%20Design/6phones.png"/>
    <img class="mobile" src="/assets/addict/mobile/6phones.png"/>
  </div>
</div>

<div class="screen inspirations" id="inspirations" >
  <app-text-paragraph [headline]="'The Beige Revolution'" [subHeadline]="'inspirations'" [text]="texts.inspirations">
  </app-text-paragraph>
  <div class="picture">
    <img class="desktop" src="/assets/addict/21%20-%20Inspo/Inspo%20board.png"/>
    <img class="mobile" src="/assets/addict/mobile/Inspo%20Board.png"/>
  </div>
</div>

<div class="screen colors" id="colors">
  <app-text-paragraph [headline]="'Calm & Pastoral Vibe'" [subHeadline]="'Colors'" [text]="texts.colors">
  </app-text-paragraph>
  <div class="inner">
    <div class="picture">
      <img class="desktop" src="/assets/addict/24%20-%20Colors/Color%20Sweatshirts%20smaller.png"/>
      <app-colors-slider class="mobile" [images]="colorsImages"></app-colors-slider>
    </div>
  </div>
</div>

<div class="screen fonts" id="fonts">
  <div class="inner">
    <app-text-paragraph [headline]="'Stylish & Elegant Fonts'" [subHeadline]="'Typography'" [text]="texts.fonts">
    </app-text-paragraph>
    <div class='picture'>
      <img class="desktop" src="/assets/addict/23 - Fonts/fonts.svg"/>
      <img class="mobile" src="/assets/addict/mobile/fonts.svg"/>
    </div>
  </div>
</div>

<div class="screen logo-show" id="logo-show">
  <img class="desktop" src="/assets/addict/22 - Logo Show/logo show.png" />
  <img class="mobile" src="/assets/addict/mobile/Branding/Branding 1.png" />
</div>

<div class="screen branding-box" id="branding-box">
  <img class="desktop" src="/assets/addict/25- Box/Box Branding.png" />
  <img class="mobile" src="/assets/addict/mobile/Branding/Box Logo 2.png" />
</div>

<div class="screen branding-bag" id="branding-bag">
  <img class="desktop" src="/assets/addict/26 - Bags/Bags Branding.png" />
  <img class="mobile" src="/assets/addict/mobile/Branding/Shopping Bag Woman 3.png" />
</div>

<div class="screen clothes" id="clothes">
  <img class="desktop" src="/assets/addict/27 - Clothes/clothes.png" />
  <img class="mobile" src="/assets/addict/mobile/Branding/Shirt 4.png" />
</div>

<div class="screen mobile jeans" id="jeans">
  <img class="mobile" src="/assets/addict/mobile/Branding/Jeans 5.png" />
</div>

<div class="screen thanks" id="thanks">
  <div class="picture">
    <img src="/assets/addict/28 - Thanks/logo round.png"/>
  </div>
  <h1 class='content'>Thanks For Viewing!</h1>
</div>

<div class="screen contact" id="contact">
  <div class="navigation">
    <div class="prev" routerLink="/beautyzone">
      <div class="content">
        <h2 class="headline">Beauty Zone</h2>
        <div class="description">UX | UI Design Mobile App</div>
      </div>
      <img class="img" src="/assets/home-page/3%20-%20Beautyzone/Phones.svg"/>
    </div>
    <div class="next">
      <div class="content">
        <h2 class="headline">Wise Home</h2>
        <div class="description">UX | UI Design Mobile & Tablet App</div>
      </div>
      <img class="img" src="/assets/home-page/4%20-%20Wisehome/mockups%20WH%20full.png"/>
    </div>

    <!--    <div class="prev" routerLink="/beautyzone">-->
    <!--      <img src="/assets/previous next/beautyzone logo.png" />-->
    <!--    </div>-->
    <!--    <div class="next" routerLink="/wisehome">-->
    <!--      <img src="/assets/previous next/wisehome logo.png" />-->
    <!--    </div>-->
  </div>
  <div class="info" id="info">
    <h1 class="headline">Start a project with me!</h1>
    <div class=container>
      <div class="mail">Amitsheleg13@gmail.com</div>
      <div class="phone">053-3350577</div>
    </div>
    <div class="goto-top">
      <img (click)="scroll(main)" src="/assets/beauty-zone/19%20-%20Next%20-contact/up.svg"/>
    </div>
  </div>

</div>


