<img class="illustration" src="/assets/mobile-unsupported.svg" />
<h2>It’s not you, it’s me…</h2>
<span class="text">
    We are deeply sorry,<br />
    Unfortunately this page isn't supported on mobile devices yet.
</span>

<div class="button">

    <a href='/'>Return Home</a>
</div>