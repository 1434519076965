<div class='inner'>
  <div class="menu-items">
    <div class="mobile-menu" (click)="toggleMenu()"><img src="assets/humburger.svg"/></div>
    <a [href]="'/'"><img class="logo" src="assets/logo.svg"/></a>
  </div>
  <div class="spacer"></div>
  <div class="spacer"></div>
  <div class="spacer"></div>
  <div class="spacer"></div>
  <div class="spacer"></div>
  <div class="spacer"></div>

  <div class="routing">
    <div class="link work" (click)="closeMenu()" [routerLink]="'/'" [fragment]="'home-my-works'">My Works</div>
    <div class="link about" (click)="closeMenu()" [routerLink]="'/'" [fragment]="'home-about'">About Me</div>
    <div class="link contact" (click)="closeMenu()" [routerLink]="'/'" [fragment]="'home-contact'">Contact Me</div>
  </div>
</div>
